import React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"
import Katharina from "../../components/pages/katharina/katharina"
import AccessWrapper from '../../components/pages/AccessWrapper'

// console.log(list)

const KatharinaPage = ({ data }) => {

  const story = data.storyblokEntry
  const content = JSON.parse(story.content)

  return(
    <Layout>
      <SEO title="Home" />
      <AccessWrapper introString='Katharina Lüdicke' content={content.artists[8]} id={8}>
        <Katharina content={content}/>
      </AccessWrapper>
    </Layout>
  )
}

export default KatharinaPage

export const query = graphql`
  query {
    storyblokEntry(slug: { eq: "artists" }) {
      content
    }
  }
`