import React, { useRef } from "react"
import style from "./katharina.module.scss"


const Katharina = ({ content }) => {

    const videos = content.katharinaVideo
    // const audios = content.katharinaAudio

    const video1 = videos[0].video.filename
    const videoClips = content.katharinavideoclips
    const videoRef = useRef()

    console.log(content)

    // const [ state, setState ] = useState({
    //     position: null
    // })

    // const position = []
    
    const handleCurrentTime = (index) => {
        const time = index * 10
        videoRef.current.currentTime = time;
        videoRef.current.play()
    }

    return(
        <div 
        className={style.katharina}
        // onMouseMove={handleMouseMove}
        >
            <div className={style.mainVideo} >
                <video ref={videoRef} src={video1} autoPlay loop muted style={{
                    // transform: `translate(-50%, -50%) rotate3d(${state.mousePosition[0]/10}, ${state.mousePosition[1]/10}, 0, ${state.mousePosition[0] * state.mousePosition[1] * 10}deg)`
                }} />
            </div>
            {
                videoClips.map((clip, i) => {

                    const index = i

                    return(
                        <div key={i} 
                        className={style.preview}
                        // onMouseEnter={() => handleCurrentTime(index)}
                        onKeyDown={() => handleCurrentTime(index)}
                        onClick={() => handleCurrentTime(index)}
                        role="button"
                        tabIndex={0}
                        style={{
                            // position: `fixed`,
                            top: `${Math.random() * 100}%`,
                            left: `${Math.random() * 100}%`,
                        }}>
                            <video
                            src={clip.filename}
                            style={{
                                width: `${200 + Math.random() * 100}px`,
                                height:' auto'
                            }}  
                            autoPlay
                            loop
                            muted
                            />
                        </div>  
                    )
                })
            }
        </div>
    )
}

export default Katharina
